const breakpoints = {
  xs: `400px`,
  sm: `550px`,
  md: `750px`,
  lg: `1000px`,
  xl: `1200px`,
  xxl: `1600px`,
}

const breakpointsArray = Object.keys(breakpoints).map(b => breakpoints[b])

const mediaQueries = Object.keys(breakpoints).reduce((result, b) => {
  result[b] = `@media (min-width: ${breakpoints[b]})`
  return result
}, {})

const space = [
  0, //      0
  0.25, //   1
  0.5, //    2
  0.75, //   3
  1, //      4
  1.25, //   5
  1.5, //    6
  1.75, //   7
  2, //      8
  2.25, //   9
  2.5, //    10
  2.75, //   11
  3, //      12
  3.25, //   13
  3.5, //    14
  3.75, //   15
  4, //      16
  4.25, //   17
  4.5, //    18
  4.75, //   19
  4.5, //    20
  5, //      21
  5.5, //    22
  6, //      23
  6.25, //   24
  7, //      25
  7.5, //    26
  8.75, //   27
  10, //     28
  12.5, //   29
].map(n => n + 'rem')

const fonts = {
  body: 'Montserrat, sans-serif',
  heading: 'inherit',
  monospace: 'Menlo, monospace',
}

const fontSizes = [
  '0.75', // '80%',   0
  '0.875', //         1
  '1', //             2
  '1.125', //         3
  '1.25', //          4
  '1.5', //           5
  '1.75', //          6
  '2', //             7
  '2.25', //          8
  '2.5', //           9
  '2.75', //          10
  '3', //             11
  '3.25', //          12
  '3.5', //           13
  '3.75', //          14
  '4', //             15
  '4.25', //          16
  '4.5', //           17
  '4.75', //          18
  '5', //             19
  '5.25', //          20
  '5.5', //           21
  '5.75', //          22
  '6', //             23
].map(n => n + 'rem')

const fontWeights = {
  light: 300,
  body: 500,
  normal: 500,
  heading: 700,
  bold: 700,
  heavy: 900,
}

const lineHeights = {
  solid: 1,
  heading: 1.25,
  button: 1.5,
  input: 1.5,
  default: 1.75,
  body: 1.75,
}

const sizes = {
  // container widths
  xs: '260px',
  sm: '540px',
  md: '720px',
  lg: '960px',
  xl: '1280px',
  xxl: '1360px',
}

const radii = {
  default: '0.25rem',
  sm: '0.2rem',
  lg: '0.3rem',
  pill: '50rem',
}

const baseColors = {
  white: '#fff',
  black: ['#000', '#0f0f0f'],
  gray: [
    '#fff', // 0
    '#f8f9fa', // 1
    '#f6f6f8', // 2
    '#e5e5e5', // 3
    '#ced4da', // 4
    '#adb5bd', // 5
    '#6c757d', // 6
    '#495057', // 7
    '#343a40', // 8
    '#212529', // 9
    '#363D4D', // 10
    '#ababab', // 11
    '#eaeaea', // 12
  ],
  red: ['#fc6f86', '#FF4C69'],
  green: '#2CC2B2',
  blue: ['#81d4ff', '#3399C6'],
  yellow: ['#ffd84c', '#FFE484'],
}

const colors = {
  ...baseColors,
  text: baseColors.black[0],
  background: baseColors.white,
  primary: baseColors.yellow[0],
  muted: baseColors.gray[3],
  error: baseColors.red[0],
  modal: {
    close: baseColors.black[0],
  },
  navigation: {
    link: baseColors.black[0],
    linkHover: baseColors.black[0],
    border: baseColors.gray[3],
    borderDesktop: baseColors.black[0],
  },
  footer: {
    text: baseColors.white,
    background: baseColors.black[0],
    link: baseColors.white,
  },
  template: {
    background: baseColors.gray[2],
    borderHover: baseColors.black[0],
    backgroundHover: baseColors.black[0],
    colorHover: baseColors.white,
  },
  cta: {
    background: baseColors.gray[2],
    border: baseColors.white,
  },
  mail: {
    border: baseColors.gray[3],
  },
  plan: {
    border: baseColors.gray[3],
  },
  preview: {
    background: baseColors.gray[2],
  },
  divider: {
    border: baseColors.gray[3],
    text: baseColors.gray[4],
  },
  form: {
    border: baseColors.gray[2],
  },
  strikeThrough: {
    background: baseColors.red[1],
  },
}

const shadows = {
  default: `0 2px 6px 0 rgba(54, 61, 77, 0.1)`,
  overlay: `0 4px 10px 0 rgba(0,0,0,0.08)`,
  main: `0 4px 12px 0 rgba(30,33,41,0.1)`,
  extra: `0 2px 6px 0 rgba(0,0,0,0.1)`,
  card: `0 8px 16px 0 rgba(0, 0, 0, 0.15)`,
}

export {
  breakpoints,
  mediaQueries,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  colors,
  shadows,
  sizes,
  radii,
}

export const theme = {
  breakpoints: breakpointsArray,
  mediaQueries,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  colors,
  shadows,
  sizes,
  radii,
}

export default theme
