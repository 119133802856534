import sbjs from 'sourcebuster'

const isBrowser = typeof window !== 'undefined'

let isInitialized = false

export const initTracking = () => {
  if (!isBrowser || isInitialized) return

  sbjs.init({
    domain: {
      host: window.location.hostname,
      isolate: true,
    },
  })

  isInitialized = true
}

const getTrackingContext = () => {
  if (!isBrowser) return ``

  if (!isInitialized) {
    throw new Error('tracking is not initialized')
  }

  const current = sbjs.get.first
  const current_add = sbjs.get.first_add

  return (
    `type=${current.typ}&utm_source=${current.src}&utm_medium=${current.mdm}&utm_campaign=${current.cmp}&utm_content=${current.cnt}&utm_term=${current.trm}` +
    `&referer=${current_add.rf}&entry=${current_add.ep}`
  )
}

export const getTracking = () => ({ context: getTrackingContext() })
