import React from 'react'
import { ThemeProvider } from '@emotion/react'
import { tryAuthenticate } from './src/utils/auth'
import { initTracking } from './src/utils/tracking'
import theme from './src/theme'

import '@fontsource/montserrat/latin-500.css'
import '@fontsource/montserrat/latin-700.css'

export const onClientEntry = () => {
  initTracking()
  tryAuthenticate()
}

export const shouldUpdateScroll = ({
  prevRouterProps: { location: prevLocation } = {},
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { modal = false, noScroll = false } = location.state || {}
  const excluded = !!location.hash

  if (location.pathname.startsWith('/templates') && prevLocation?.state.modal) {
    const prevPosition = getSavedScrollPosition({ pathname: location.pathname })
    window.scrollTo(...(prevPosition || [0, 0]))
    return
  }

  return !modal && !noScroll && !excluded
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!location) return

  const noReferrer = location.state?.noReferrer
  const referrer = prevLocation?.pathname
  const prevReferrer = prevLocation?.state?.referrer

  location.state = location.state || {}
  location.state.referrer = (noReferrer ? prevReferrer : referrer) || null
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)
