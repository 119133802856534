import { API_URL, APP_URL } from '../environment'
import { gtmEvent } from './gtm'

const isBrowser = typeof window !== 'undefined'

let currentUser = {}

export const getLoginUrl = next => {
  return `${APP_URL}${next || '/sign-in'}`
}

export const getUser = () => currentUser

const setUser = user => {
  currentUser = user || {}
  localStorage.setItem('isLoggedIn', currentUser.id ? 'true' : 'false')
}

const redirectTo = url => (window.location = url)

export const isAuthenticated = () => {
  if (!isBrowser) return

  return localStorage.getItem('isLoggedIn') === 'true'
}

export const handleAuthentication = ({
  user,
  isNew = false,
  provider = 'direct',
  login_url,
  next,
}) => {
  if (!isBrowser || !user) return

  const loginUrl = login_url + (next ? `&next=${encodeURIComponent(next)}` : ``)

  setUser(user)

  gtmEvent(isNew ? 'signUp' : 'signIn', {
    userId: user.id,
    provider,
    eventCallback: () => redirectTo(loginUrl),
    eventTimeout: 2000,
  })
}

export const tryAuthenticate = () => {
  if (!isBrowser) return

  const url = `${API_URL}/users/login_check`
  fetch(url, { credentials: 'include' })
    .then(resp => resp.json())
    .then(({ status, user }) => setUser(status ? user : {}))
}

export const logout = () => {
  if (!isBrowser) return

  setUser({})
}
