const createFunctionWithTimeout = (callback, timeout = 2000) => {
  let called = false
  const raceCallback = () => {
    if (!called) {
      called = true
      callback()
    }
  }
  setTimeout(raceCallback, timeout)
  return raceCallback
}

export function gtmEvent(name, params = {}) {
  if (typeof window === 'undefined') return

  if (typeof params.eventCallback === 'function') {
    params = {
      ...params,
      eventCallback: createFunctionWithTimeout(params.eventCallback),
    }
  }

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: name,
    ...params,
  })
}
